<template>
<div id="SlidingTabs">

  <nav id="navigation">
    <div class="tab active">
      <div class="tab__icon"></div>
      <div class="tab__txt">Images</div>
    </div>
    <div class="tab">
      <div class="tab__icon"></div>
      <div class="tab__txt">Options</div>
    </div>
    <div class="tab">
      <div class="tab__icon"></div>
      <div class="tab__txt">Location</div>
    </div>
    <div class="tab">
      <div class="tab__icon"></div>
      <div class="tab__txt">Labels</div>
    </div>
    <div class="tab">
      <div class="tab__icon"></div>
      <div class="tab__txt">Price</div>
    </div>
    <div id="tab__overlay" style="left: 5px; right: 461px;"></div>
  </nav>

</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'SlidingTabs',
  setup () {

    onMounted(() => {


      function setPosition (closest) {
        if (closest) {
          let overlay = document.querySelector('#tab__overlay');
          let navigation = document.querySelector('#navigation');
          let _navigation = navigation.getBoundingClientRect();
          let active = document.querySelectorAll('.active');
          [...active].forEach(item => item.classList.remove('active'));
          let _closest = closest.getBoundingClientRect();

          closest.classList.add('active');

          let previousLeft = parseInt(overlay.style.left);
          let left = Math.abs(_navigation.left - _closest.left) - 10;
          let right = Math.abs(_navigation.right - _closest.right) - 10;

          previousLeft < left ?
            overlay.style.transition = 'left 1s cubic-bezier(0.4, 0, 0.2, 1), right .5s cubic-bezier(0.4, 0, 0.2, 1)'
            :
            overlay.style.transition = 'left .5s cubic-bezier(0.4, 0, 0.2, 1), right 1s cubic-bezier(0.4, 0, 0.2, 1)';

          overlay.style.left = left + 'px';
          overlay.style.right = right + 'px';

        }
      }

      let tabs = document.querySelectorAll('.tab');

      ;[...tabs].forEach(tab => {
        tab.onmousedown = event => {
          let tab = event.target.closest('.tab');
          setPosition(tab);
        };
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
#SlidingTabs
  position absolute
  overflow hidden
  z-index 100
  top 0
  right 0
  bottom 0
  left 0
  display flex
  align-items center
  justify-content center
  #navigation
    position relative
    max-width 600px
    height 100px
    box-shadow 0 40px 35px 0 rgba(10, 10, 10, 0.175), 0 4px 20px 0 rgba(0, 0, 0, 0.2)
    background-color #fff
    border 15px solid #fff
    border-radius 100px
    display flex
    .tab
      position relative
      flex 1
      display flex
      align-items center
      flex-direction column
      justify-content center
      width 130px
      cursor pointer
      transition .3s
      z-index 2
      &:not(.active):hover
        .tab__icon
          transform scale(1.2)
      &.active
        .tab__icon
          transform translate(0px, -5px)
        .tab__txt
          opacity 1
          transform translate(0, 5px)
      &:nth-child(1)
        .tab__icon
          background-image url("../../public/icons-slider/Gallery.svg")
      &:nth-child(2)
        .tab__icon
          background-image url("../../public/icons-slider/Settings.svg")
      &:nth-child(3)
        .tab__icon
          background-image url("../../public/icons-slider/Compass.svg")
      &:nth-child(4)
        .tab__icon
          background-image url("../../public/icons-slider/Tags.svg")
      &:nth-child(5)
        .tab__icon
          background-image url("../../public/icons-slider/Money_Protection.svg")
      .tab__icon
        transition transform .3s linear, background-size .3s linear
        transform translate(0, 10px)
        background-repeat no-repeat
        background-position center
        background-size 25px
        height 30px
        width 30px
      .tab__txt
        transition transform .3s linear, opacity .5s linear
        transform translate(0, 10px)
        font 400 15px Averta
        user-select none
        color #4c4d4e
        opacity 0
    #tab__overlay
      background-color #e4f2ff
      border-radius 20px
      position absolute
      top 0
      bottom 0
</style>